<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_awal"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_mulai"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_akhir"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_selesai"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Umur
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli
                    </template>
                    <multiselect
                      :options="listPoli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli"
                      size="sm"
                      v-model="data_search.ms_poli_id"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Jenis Kelamin
                    </template>
                    <multiselect
                      :options="$store.state.data_static.jenis_kelamin"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.jenis_kelamin"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kunjungan
                    </template>
                    <multiselect
                      :options="listKunjungan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.jenis_kunjungan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kasus
                    </template>
                    <multiselect
                      :options="listKasus"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.jenis_kasus"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - PKPR</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th rowspan="3" style="width: 10px; position:sticky; left:0; min-width:50px; background-color: #fff;">No</b-th>
                        <b-th rowspan="3" style="position:sticky; left:50px; min-width:150px; background-color: #fff;">Tanggal</b-th>
                        <b-th rowspan="3" style="position:sticky; left:200px; min-width:160px; background-color:#fff">Nama Pasien</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">NIK</b-th>
                        <b-th rowspan="3" style="min-width: 130px;">Jenis Kelamin</b-th>
                        <b-th rowspan="3" style="min-width: 200px;">Alamat</b-th>
                        <b-th rowspan="3" style="min-width: 130px;">Pekerjaan	</b-th>
                        <b-th rowspan="3" style="min-width: 200px;">Tempat & Tgl.Lahir	</b-th>
                        <b-th rowspan="2" colspan="3" style="min-width: 100px;">Ayah</b-th>
                        <b-th rowspan="2" colspan="3" style="min-width: 100px;">Ibu</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Anak ke / dari Saudara</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Warga Negara</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Sekolah</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kelas</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Status Perkawinan</b-th>
                        <b-th rowspan="2" colspan="3" style="min-width: 100px;">Umur</b-th>
                        <b-th 
                          :rowspan="pkpr.data ? '1' : '2'" 
                          :colspan="pkpr.data ? pkpr.data.length*2 : '2'"
                          v-for="(pkpr, key) of data_pkpr" 
                          :key="key" style="min-width: 100px;"
                        >{{pkpr.nama}}</b-th>
                        <b-th rowspan="2" colspan="6" style="min-width: 100px;">Anamnesa</b-th>
                        <b-th rowspan="2" colspan="8" style="min-width: 100px;">Anamnesa dengan Pendekatan HEEADSS</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Dokter</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Perawat</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th 
                          colspan="2"
                          v-for="(pkpr, key) of sub_data_pkpr" 
                          :key="key" style="min-width: 100px;"
                        >{{pkpr.nama}}</b-th>
                        <!-- <b-th style="min-width: 100px;">2</b-th> -->
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th rowspan="1" style="min-width: 150px;">Nama</b-th>
                        <b-th rowspan="1" style="min-width: 150px;">Pendidikan</b-th>
                        <b-th rowspan="1" style="min-width: 150px;">Pekerjaan</b-th>
                        <b-th rowspan="1" style="min-width: 150px;">Nama</b-th>
                        <b-th rowspan="1" style="min-width: 150px;">Pendidikan</b-th>
                        <b-th rowspan="1" style="min-width: 150px;">Pekerjaan</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Tahun</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Bulan</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Hari</b-th>
                        <b-th v-for="(all, keyAll) of all_data_pkpr" :key="keyAll" :style="`min-width: ${all.type == 'status' ? 50 : 150}px;`">{{all.type == 'status' ? 'Status' : 'Keterangan'}}</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Masalah Utama</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Latar Belakang</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Alternatif</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Keputusan</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Observasi</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Konselor</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Home</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Employment Education</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Eating</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Activity</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Drugs</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Sexuality</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Safety</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Suicide Depression</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ item.no }}</b-td>
                        <b-td style="position:sticky;left:50px;min-width:150px;background-color: #fff;">{{ $moment(item.tanggal_pkpr).format('LL') }}</b-td>
                        <b-td style="position:sticky;left:200px;min-width:160px;background-color: #fff;">{{ item.nama_lengkap }}</b-td>
                        <b-td>{{ item.nik }}</b-td>
                        <b-td>{{ item.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki' }}</b-td>
                        <b-td>{{ item.alamat_ktp || '-' }}</b-td>
                        <b-td>{{ item.pekerjaan || '-' }}</b-td>
                        <b-td>{{ item.tampat_tanggal_lahir || '-' }}</b-td>
                        <b-td>{{ item.nama_ayah || '-' }}</b-td>
                        <b-td>{{ item.pendidikan_ayah ? item.pendidikan_ayah.nama_pendidikan || '-' : '-' }}</b-td>
                        <b-td>{{ item.pekerjaan_ayah || '-' }}</b-td>
                        <b-td>{{ item.nama_ibu || '-' }}</b-td>
                        <b-td>{{ item.pendidikan_ibu ? item.pendidikan_ibu.nama_pendidikan || '-' : '-' }}</b-td>
                        <b-td>{{ item.pekerjaan_ibu || '-' }}</b-td>
                        <b-td>{{ item.anak_ke || '-' }} / {{ item.bersaudara || '-' }}</b-td>
                        <b-td>{{ item.warga_negara || '-' }}</b-td>
                        <b-td>{{ item.sekolah || '-' }}</b-td>
                        <b-td>{{ item.kelas || '-' }}</b-td>
                        <b-td>{{ item.status_kawin || '-' }}</b-td>
                        <b-td>{{ item.usia_tahun || '0' }}</b-td>
                        <b-td>{{ item.usia_bulan || '0' }}</b-td>
                        <b-td>{{ item.usia_hari || '0' }}</b-td>
                        <b-td class="text-center" v-for="(all, idxAll) of value_all_data_pkpr[idx]" :key="idxAll">{{ all || '-' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.masalah_utama || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.latar_belakang_masalah || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.alternatif_pemecah_masalah || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.keputusan_tindakan_client || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.observasi || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_pkpr ? item.data_anamnesa_pkpr.konselor || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.home || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.employment_education || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.eating || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.actifity || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.drugs || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.sexuality || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.safety || '-' : '' }}</b-td>
                        <b-td>{{ item.data_anamnesa_heads ? item.data_anamnesa_heads.suicide || '-' : '' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- <pre>{{all_data_pkpr.length}}{{all_data_pkpr}}</pre>
      {{value_all_data_pkpr}} -->
    </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      total: null,
      data_search: {
        tanggal_mulai: null,
        tanggal_selesai: null,
        ms_poli_id: null,
        jenis_kelamin: null,
        jenis_kunjungan: null,
        usia_tahun_mulai: 0,
        usia_bulan_mulai: 0,
        usia_hari_mulai: 0,
        usia_tahun_selesai: 0,
        usia_bulan_selesai: 0,
        usia_hari_selesai: 0,
        jenis_kasus: null,
      },
      totalRows: 0,
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      listKunjungan: [
        {value: 'baru', text: 'Baru'},
        {value: 'lama', text: 'Lama'},
      ],
      listKasus: [
        {value: 'Gangguan haid', text: 'Gangguan haid'},
        {value: 'Seks pranikah', text: 'Seks pranikah'},
        {value: 'Kehamilan diinginkan', text: 'Kehamilan diinginkan'},
        {value: 'Kehamilan tak diinginkan', text: 'Kehamilan tak diinginkan'},
        {value: 'Persalinan Remaja', text: 'Persalinan Remaja'},
        {value: 'Abortus', text: 'Abortus'},
        {value: 'Anemia', text: 'Anemia'},
        {value: 'KEK', text: 'KEK'},
        {value: 'Obesitas', text: 'Obesitas'},
        {value: 'Rokok', text: 'Rokok'},
        {value: 'Alkohol', text: 'Alkohol'},
        {value: 'Selain Rokok & Alkohol', text: 'Selain Rokok & Alkohol'},
        {value: 'Infeksi Menular Seksual', text: 'Infeksi Menular Seksual'},
        {value: 'Infeksi Saluran Reproduksi', text: 'Infeksi Saluran Reproduksi'},
        {value: 'HIV', text: 'HIV'},
        {value: 'AIDS', text: 'AIDS'},
        {value: 'Masalah Psikologis, Kejiwaan', text: 'Masalah Psikologis, Kejiwaan'},
        {value: 'Kecanduan Gadget', text: 'Kecanduan Gadget'},
        {value: 'Orientasi Seksual', text: 'Orientasi Seksual'},
        {value: 'Disabilitas Mental', text: 'Disabilitas Mental'},
        {value: 'Pernikahan Dini', text: 'Pernikahan Dini'},
        {value: 'Kekerasan terhadap Anak', text: 'Kekerasan terhadap Anak'},
        {value: 'Disabilitas Fisik', text: 'Disabilitas Fisik'},
        {value: 'Kesulitan Belajar', text: 'Kesulitan Belajar'},
        {value: 'Masalah Lainnya', text: 'Masalah Lainnya'},

      ],
      listPoli: [],
      items: [],
      filter: null,
      tableBusy: false,
      disabled_field: false,
      data_pkpr: [
        {
          nama: 'Gangguan haid',
          value: null,
          text: '',
        },
        {
          nama: 'Seks pranikah',
          value: null,
          text: '',
        },
        {
          nama: 'Remaja Hamil',
          data: [
            {
              nama: 'Kehamilan diinginkan',
              value: null,
              text: '',
            },
            {
              nama: 'Kehamilan tak diinginkan',
              value: null,
              text: '',
            },
          ],
        },
        {
          nama: 'Persalinan Remaja',
          value: null,
          text: '',
        },
        {
          nama: 'Abortus',
          value: null,
          text: '',
        },
        {
          nama: 'Gangguan Gizi',
          data: [
            {
              nama: 'Anemia',
              value: null,
              text: '',
            },
            {
              nama: 'KEK',
              value: null,
              text: '',
            },
            {
              nama: 'Obesitas',
              value: null,
              text: '',
            },
          ],
        },
        {
          nama: 'NAPZA',
          data: [
            {
              nama: 'Rokok',
              value: null,
              text: '',
            },
            {
              nama: 'Alkohol',
              value: null,
              text: '',
            },
            {
              nama: 'Selain Rokok & Alkohol',
              value: null,
              text: '',
            },
          ],
        },
        {
          nama: 'Infeksi Menular Seksual',
          value: null,
          text: '',
        },
        {
          nama: 'Infeksi Saluran Reproduksi',
          value: null,
          text: '',
        },
        {
          nama: 'HIV',
          value: null,
          text: '',
        },
        {
          nama: 'AIDS',
          value: null,
          text: '',
        },
        {
          nama: 'Masalah Psikologis, Kejiwaan',
          value: null,
          text: '',
        },
        {
          nama: 'Kecanduan Gadget',
          value: null,
          text: '',
        },
        {
          nama: 'Orientasi Seksual',
          value: null,
          text: '',
        },
        {
          nama: 'Disabilitas Mental',
          value: null,
          text: '',
        },
        {
          nama: 'Pernikahan Dini',
          value: null,
          text: '',
        },
        {
          nama: 'Kekerasan terhadap Anak',
          value: null,
          text: '',
        },
        {
          nama: 'Disabilitas Fisik',
          value: null,
          text: '',
        },
        {
          nama: 'Kesulitan Belajar',
          value: null,
          text: '',
        },
        {
          nama: 'Masalah Lainnya',
          value: null,
          text: '',
        },
      ],
      value_all_data_pkpr: [],
    };
  },
  computed: {
    sub_data_pkpr(){ //hanya ambil sub
      const vm = this
      const sub_data_pkpr = []
      for (let i = 0; i < vm.data_pkpr.length; i++) {
        const x = vm.data_pkpr[i];
        if(x.data){
          for (let u = 0; u < x.data.length; u++) {
            const y = x.data[u];
            sub_data_pkpr.push(y)
          }
        }
      }
      return sub_data_pkpr
    },
    all_data_pkpr(){ //sub dan head jadikan satu level (status + keterangan)
      const vm = this
      const all = []
      console.log('jalan 1')
      for (let i = 0; i < vm.data_pkpr.length; i++) {
        const x = vm.data_pkpr[i];
        if(x.data){
          for (let u = 0; u < x.data.length; u++) {
            const y = x.data[u];
            all.push({...y, type: 'status'})
            all.push({...y, type: 'text'})
          }
        }else{
          all.push({...x, type: 'status'})
          all.push({...x, type: 'text'})
        }
      }
      console.log('all', all)
      return all
    }
  },
  activated(){
    this.getData()
    this.reset()
  },
  methods: {
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        const res = await vm.$axios.post('/pkpr/list_laporan_pkpr', {
          ...vm.data_search,
          tanggal_mulai: vm.$moment(vm.data_search.tanggal_mulai).format('YYYY-MM-DD'),
          tanggal_selesai: vm.$moment(vm.data_search.tanggal_selesai).format('YYYY-MM-DD'),
          ms_poli_id: vm.data_search.ms_poli_id ? vm.data_search.ms_poli_id.ms_poli_id : null,
          jenis_kelamin: vm.data_search.jenis_kelamin ? vm.data_search.jenis_kelamin.value : null,
          jenis_kunjungan: vm.data_search.jenis_kunjungan ? vm.data_search.jenis_kunjungan.value : null,
          jenis_kasus: vm.data_search.jenis_kasus ? vm.data_search.jenis_kasus.value : null,
        })
        console.log('pkpr/list_laporan_p kpr', res)
        vm.items = []
        vm.value_all_data_pkpr = []
        if(res.data.status == 200){
          vm.totalRows = res.data.data.length
          for (let i = 0; i < res.data.data.length; i++) {
            const x = res.data.data[i];
            const value_all_data_pkpr = []
            for (let u = 0; u < vm.all_data_pkpr.length; u++) {
              const y = vm.all_data_pkpr[u];
              let o = 0
              for (; o < x.data_pkpr.length; o++) {
                const z = x.data_pkpr[o];
                if(y.nama == z.nama){
                  if(y.type == 'status') value_all_data_pkpr.push(z.value ? 'ya' : 'tidak')
                  if(y.type == 'text') value_all_data_pkpr.push(z.text)
                  break
                }
              }
              if(o == x.data_pkpr.length){
                if(y.type == 'status') value_all_data_pkpr.push('-')
                if(y.type == 'text') value_all_data_pkpr.push('-')
              }
            }
            vm.value_all_data_pkpr.push(value_all_data_pkpr)
            vm.items.push({
              no: i+1,
              ...x,
              pendidikan_ayah: JSON.parse(x.pendidikan_ayah) || {},
              pendidikan_ibu: JSON.parse(x.pendidikan_ibu) || {},
            })
          }
          console.log('pkpr/list_laporan_pkpr', vm.items)
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_mulai:  new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_selesai: new Date(vm.$store.state.tanggal_sesi),
        ms_poli_id: null,
        jenis_kelamin: null,
        jenis_kunjungan: null,
        usia_tahun_mulai: 0,
        usia_bulan_mulai: 0,
        usia_hari_mulai: 0,
        usia_tahun_selesai: 0,
        usia_bulan_selesai: 0,
        usia_hari_selesai: 0,
        jenis_kasus: null,
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
